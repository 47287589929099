function ExpandableCollection(parent, opts) {
  this._init(parent, opts);
};

ExpandableCollection.prototype._init = function(parent, opts) {
  this._parent = parent;
  this._options = opts || {};

  var extendOptions = function(obj, defaultObj) {
    for (var opt in defaultObj) {
      if (typeof obj[opt] === 'undefined') {
        obj[opt] = defaultObj[opt];
      } else if (typeof defaultObj[opt] === 'object') {
        extendOptions(obj[opt], defaultObj[opt]);
      }
    }
  };

  extendOptions(this._options, ExpandableCollection.DEFAULT_OPTIONS);

  this._initExpandables();
};

ExpandableCollection.prototype._initExpandables = function() {
  this._expandables = Array.prototype.slice.call(this._parent.querySelectorAll(this._options.selector)).map(function(element) {
    return Expandable.attach(element, this._options.expandable);
  }.bind(this));

  this._expandables.forEach(function(expandable) {
    expandable._options.onExpandStart = function() {
      this._currentExpandable = expandable;
      this.collapseAll();
    }.bind(this);
  }.bind(this));
};

ExpandableCollection.prototype.isExpanded = function(idx) {
  return idx >= 0 && idx < this._expandables.length && this._expandables[idx].isExpanded();
};

ExpandableCollection.prototype.expand = function(idx) {
  if (idx >= 0 && idx < this._expandables.length && !this._expandables[idx].isExpanded()) {
    this._expandables[idx].expand();
  }
};

ExpandableCollection.prototype.collapse = function(idx) {
  if (idx >= 0 && idx < this._expandables.length && this._expandables[idx].isExpanded()) {
    this._expandables[idx].collapse();
  }
};

ExpandableCollection.prototype.expandAll = function() {
  this._expandables.forEach(function(expandable) {
    expandable.expand();
  });
};

ExpandableCollection.prototype.collapseAll = function() {
  this._expandables.forEach(function(expandable) {
    expandable.collapse();
  });
};

ExpandableCollection.attach = function(parent, opts) {
  return new ExpandableCollection(parent, opts);
};

ExpandableCollection.DEFAULT_OPTIONS = {
  selector: '*',
  expandable: Expandable.DEFAULT_OPTIONS
};
