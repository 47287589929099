function QuoteCollection(parent, opts) {
  this._init(parent, opts);
};

QuoteCollection.prototype._init = function(parent, opts) {
  this._parent = parent;
  this._options = {
    animate: {
      enabled: opts.animate.enabled || false,
      duration: opts.animate.duration || 0.5
    }
  };

  this._quotes = Array.prototype.slice.call(parent.querySelectorAll('.item.quote')).map(function(quoteItem) {
    return Quote.attach(quoteItem, {
      onContentOpened: this._contentOpened.bind(this),
      animate: this._options.animate
    });
  }.bind(this));
};

QuoteCollection.prototype._contentOpened = function(openedQuote) {
  this._quotes.filter(function(quote) {
    return quote !== openedQuote;
  }).forEach(function(quote) {
    quote.closeContent();
  });
};

QuoteCollection.attach = function(parent, opts) {
  return new QuoteCollection(parent, opts);
};

function Quote(parent, opts) {
  this._init(parent, opts);
};

Quote.prototype._init = function(parent, opts) {
  this._parent = parent;
  this._options = {
    animate: {
      enabled: opts.animate.enabled || false,
      duration: opts.animate.duration || 0.5
    },
    onContentOpened: opts.onContentOpened || function() {},
    onContentClosed: opts.onContentClosed || function() {}
  };

  this._buttons = Array.prototype.slice.call(parent.querySelectorAll('.quote .content .button'));
  this._content = parent.querySelector('.item > .content');
  this._contentHeight = this._content.offsetHeight;
  //TweenLite.set(this._parent, {height: this._parent.offsetHeight});

  this._contentOpened = false;

  this._buttons.forEach(function(button) {
	  button.addEventListener('click', this._onToggleContentOpen.bind(this));
  }.bind(this));
};

Quote.prototype._onToggleContentOpen = function(evt) {
  evt.preventDefault();

  if (this._contentOpened) {
    this.closeContent();
  } else {
    this.openContent();
  }
};

Quote.prototype.openContent = function() {
  if (!this._contentOpened) {
    this._buttons.forEach(function(button) { button.classList.remove('more')});
    this._buttons.forEach(function(button) { button.classList.add('less')});
    this._parent.classList.add('active');
    TweenLite.set(this._parent, {'marginBottom': ('+=' + this._contentHeight + 'px')});

    this._contentOpened = true;
    this._options.onContentOpened(this);

    //var tl = new TimelineLite({
    //  paused: true,
    //  onCompleteScope: this,
    //  onComplete: function() {
    //    this._contentOpened = true;
    //    this._options.onContentOpened(this);
    //
    //    tl.set(this._buttons, {className: '-=more'});
    //    tl.set(this._buttons, {className: '+=less'});
    //    tl.set(this._parent, {className: '+=active'});
    //  }
    //});
    //
    //if (this._options.animate.enabled) {
    //  tl.to(this._parent, this._options.animate.duration, {'marginBottom': ('+=' + this._contentHeight + 'px')}, 0);
    //  tl.from(this._content, this._options.animate.duration, {height: 0}, 0);
    //} else {
    //  tl.set(this._parent, {'marginBottom': ('+=' + this._contentHeight + 'px')});
    //}
    //
    //tl.play();
  }
};

Quote.prototype.closeContent = function() {
  if (this._contentOpened) {
    //var tl = new TimelineLite({
    //  paused: true,
    //  onCompleteScope: this,
    //  onComplete: function() {
    //    this._contentOpened = false;
    //    this._options.onContentClosed(this);
    //
    //    tl.set(this._buttons, {className: '-=less'});
    //    tl.set(this._buttons, {className: '+=more'});
    //    tl.set(this._parent, {className: '-=active'});
    //  }
    //});
    //
    //
    //if (this._options.animate.enabled) {
    //  tl.to(this._parent, this._options.animate.duration, {'marginBottom': ('-=' + this._contentHeight + 'px')}, 0);
    //  tl.to(this._content, this._options.animate.duration, {height: 0}, 0);
    //} else {
    //  tl.set(this._parent, {'marginBottom': ('-=' + this._contentHeight + 'px')});
    //}
    //
    //tl.play();

	  this._buttons.forEach(function(button) { button.classList.remove('less')});
	  this._buttons.forEach(function(button) { button.classList.add('more')});

    this._parent.classList.remove('active');
    TweenLite.set(this._parent, {'marginBottom': ('-=' + this._contentHeight + 'px')});

    //if (this._options.animate.enabled) {
    //  TweenLite.to(this._content, this._options.animate.duration, {height: 0});
    //  TweenLite.set(this._content, {height: 'auto'});
    //}

    this._contentOpened = false;
    this._options.onContentClosed(this);
  }
};

Quote.attach = function(parent, opts) {
  return new Quote(parent, opts);
};