class HotspotImage {
	static init(el) {
		return new HotspotImage(el);
	}

	constructor(el) {
		this.el = el;
		this.hotspots = Array.from(this.el.querySelectorAll('.hotspot-image__hotspot'));
		this.openedHotspotEl = null;

		this.attachListeners();
	}

	attachListeners() {
		this.hotspots.forEach(hotspotEl => this.initHotspot(hotspotEl));
	}

	initHotspot(hotspotEl) {
		const onClickHotspotMarker = clickedHotspotEl => {
			if (clickedHotspotEl.classList.toggle('is-open')) {
				if (this.openedHotspotEl !== null) {
					this.openedHotspotEl.classList.remove('is-open');
				}

				this.openedHotspotEl = clickedHotspotEl;
			}
		};

		const onClickHotspotContentIcon = clickedHotspotEl => {
			clickedHotspotEl.classList.remove('is-open');
			this.openedHotspotEl = null;
		};

		hotspotEl.querySelector('.hotspot-image__hotspot-marker')
			.addEventListener('click', () => onClickHotspotMarker(hotspotEl));

		hotspotEl.querySelector('.hotspot-image__hotspot-content-control')
			.addEventListener('click', () => onClickHotspotContentIcon(hotspotEl));
	}
}
