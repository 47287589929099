function Quiz(el) {
  return this._init(el);
}

Quiz.prototype._init = function(el) {
  this._el = el;
  this._entries = [].slice.call(this._el.querySelectorAll('.quiz-entry'));
  this._result = this._el.querySelector('.quiz__result-content');
  this._resultPossibilitiesEl = this._el.querySelector('.quiz__result-possibilities-content');
  this._resultPossibilities = [].slice.call(this._resultPossibilitiesEl.querySelectorAll('.quiz__result-possibility'));

  this._numEntries = this._entries.length;
  this._numEntriesDone = 0;
  this._lastNumPoints = NaN;

  const that = this;
  this._entries.forEach(function(entry) {
    let entryDone = false;
    [].slice.call(entry.querySelectorAll('.input-radio__input')).forEach(function(entryInput) {
      entryInput.addEventListener('change', function() {
        if (!entryDone && entryInput.checked) {
          entryDone = true;
          that._numEntriesDone++;
        }

        if (that._numEntriesDone === that._numEntries) {
          that._calculateResult();
        }
      });
    })
  });

  let resultsOpen = false;
  const expandButton = this._el.querySelector('.quiz__result-possibilities-heading');
  expandButton.addEventListener('click', () => {
    resultsOpen = !resultsOpen;

    expandButton.classList.toggle('less', resultsOpen);
    expandButton.classList.toggle('more', !resultsOpen);
  });

  Expandable.attach(this._el.querySelector('.quiz__result-possibilities'), {
    direction: Expandable.DIRECTION_DOWN,
    contentSelector: '.quiz__result-possibilities-content',
    triggerSelectors: ['.quiz__result-possibilities-heading'],
    push: false,
    animation: {
      enabled: true,
      force3D: true,
      duration: 0.25
    },
    onExpandStart: () => {
      expandButton.classList.remove('more');
      expandButton.classList.add('less');
    },
    onCollapseStart: () => {
      expandButton.classList.remove('less');
      expandButton.classList.add('more');
    }
  });

  HeightEqualizer.attachToSelector('.quiz__entries', {
    selectors: ['.quiz-entry__question', '.quiz-entry__answers'],
    recreateRows: true,
    animate: true,
    immediateEqualization: true,
    equalizeOnResize: true
  });
};

Quiz.prototype._calculateResult = function() {
  let numPoints = 0;
  [].slice.call(this._el.querySelectorAll('input:checked')).forEach(function(entryInput) {
    numPoints += parseInt(entryInput.parentNode.parentNode.dataset.points);
  });

  if (numPoints !== this._lastNumPoints) {
    this._lastNumPoints = numPoints;

    const that = this;
    this._resultPossibilities.forEach(function(resultPossibility) {
      const pointsMin = parseInt(resultPossibility.dataset.pointsMin);
      const pointsMax = parseInt(resultPossibility.dataset.pointsMax);

      if (numPoints >= pointsMin && numPoints <= pointsMax) {
        if (that._result.classList.contains('is-empty')) {
          that._result.classList.remove('is-empty');
        }

        if (!that._result.classList.contains('is-empty')) {
          that._resultPossibilitiesEl.appendChild(that._result.lastChild);
        }

        that._result.appendChild(resultPossibility);
      }
    })
  }
};

Quiz.attach = function(el) {
  return new Quiz(el);
};