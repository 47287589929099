window.addEventListener('DOMContentLoaded', () => {
	const anchorNavigationEl = document.getElementById('anchor-navigation');
	const siteHeaderEl = document.getElementById('site-header');
	const stickyHeaderEl = document.getElementById('sticky-header');
	let siteHeaderElHeight = siteHeaderEl.getBoundingClientRect().height;

	const onWindowScroll = () => {
		if (siteHeaderEl !== null && stickyHeaderEl !== null) {
			const shouldBeVisible = Math.abs(siteHeaderEl.getBoundingClientRect().top) > siteHeaderElHeight;
			const isVisible = stickyHeaderEl.classList.contains('is-visible');

			// Checks for current state are important for IE11. If we were to simply toggle the state, IE would actually
			// re-set the style, resulting in a "flickering" or re-starting of the animation.
			if (shouldBeVisible && !isVisible) {
				stickyHeaderEl.classList.add('is-visible', shouldBeVisible);
			} else if (!shouldBeVisible && isVisible) {
				stickyHeaderEl.classList.remove('is-visible', shouldBeVisible);
			}
		}

		if (anchorNavigationEl !== null) {
			const shouldBeSticky = Math.abs(siteHeaderEl.getBoundingClientRect().top) > siteHeaderElHeight;

			// Checks for current state are important for IE11. If we were to simply toggle the state, IE would actually
			// re-set the style, resulting in a "flickering".
			if (shouldBeSticky && !anchorNavigationEl.classList.contains('is-sticky')) {;
				anchorNavigationEl.style.top = Math.max(anchorNavigationEl.getBoundingClientRect().top, stickyHeaderEl.getBoundingClientRect().height) + 'px';
				anchorNavigationEl.classList.add('is-sticky')
			} else if (!shouldBeSticky && anchorNavigationEl.classList.contains('is-sticky')) {
				anchorNavigationEl.style.top = 'auto';
				anchorNavigationEl.classList.remove('is-sticky');
			}
		}
	};
	const onWindowResize = () => {
		siteHeaderElHeight = siteHeaderEl.getBoundingClientRect().height;
	};
	const onWindowLoad = () => {
		initAnchorNavigation();
		initHotspotImages();
		initBoxCollections();
		initDialog();
	};

	const initAnchorNavigation = () => {
		if (anchorNavigationEl !== null) {
			AnchorNavigation.init(anchorNavigationEl);
		}
	};

	const initHotspotImages = () => {
		Array.from(document.querySelectorAll('.hotspot-image')).forEach(HotspotImage.init);
	};

	const initBoxCollections = () => {
		Array.from(document.querySelectorAll('.box-collection')).forEach(boxCollectionEl => {
			expandableCollectionsEqualizers = HeightEqualizer.attachToElement(boxCollectionEl, {
				selectors: [expandableEqualizeSelector],
				recreateRows: true,
				animate: true,
				immediateEqualization: true,
				equalizeOnResize: true
			});
		});
	};

	const initDialog = () => {
		const dialogContainerEl = document.getElementById('dialog');

		if (dialogContainerEl !== null) {
			LightboxDialog.init(dialogContainerEl);
		}
	};

	window.addEventListener('resize', onWindowResize);
	window.addEventListener('scroll', onWindowScroll);
	window.addEventListener('load', onWindowLoad);
});
