let initComments = function() {
  document.querySelectorAll('.comments__list-entry > .comment').forEach((comment) => {
    let replyButton = comment.querySelector('.comment__reply-button');
    let editButton = comment.querySelector('.comment__controls-edit');
    let deleteButton = comment.querySelector('.comment__controls-delete .icon-container__link');

    if (replyButton) {
      replyButton.addEventListener('click', (evt) => {
        evt.preventDefault();
        comment.classList.toggle('is-open');
      });
    }

    if (editButton) {
      editButton.addEventListener('click', (evt) => {
        evt.preventDefault();
        comment.classList.add('is-editable');
      });
    }

    let likesInfo = comment.querySelector('.comment__likes');

    if (likesInfo) {
      likesInfo.querySelector('.comment__likes-title').addEventListener('click', (evt) => {
        evt.preventDefault();

        // likesInfo.classList.toggle('is-showing-usernames');
      });
    }

    // Delete
    if (deleteButton) {
      deleteButton.addEventListener('click', (evt) => {
        evt.preventDefault();
        comment.classList.toggle('is-showing-delete');
      });

      comment.querySelector('.delete-comment-form__form-cancel').addEventListener('click', () => {
        comment.classList.remove('is-showing-delete');
      });
    }

    if (!comment.classList.contains('is-deleted')) {
      let editCommentForm = comment.querySelector('.edit-comment-form');
      let previewButton = editCommentForm.querySelector('.edit-comment-form__form-preview');
      let editPreviewButton = editCommentForm.querySelector('.edit-comment-form__form-edit-preview');
      let cancelButton = editCommentForm.querySelector('.edit-comment-form__form-cancel');
      let messageInput = editCommentForm.querySelector('.edit-comment-form__form-message textarea');

      let commentContent = comment.querySelector('.comment__message');
      let originalMessage = commentContent.textContent;

      if (previewButton) {
        previewButton.addEventListener('click', () => {
          commentContent.innerHTML = nl2br(messageInput.value);
          comment.classList.add('is-showing-preview');
          editCommentForm.classList.add('is-showing-preview');
        });
      }

      if (editPreviewButton) {
        editPreviewButton.addEventListener('click', () => {
          editCommentForm.classList.remove('is-showing-preview');
          comment.classList.remove('is-showing-preview');
        });
      }

      if (cancelButton) {
        cancelButton.addEventListener('click', () => {
          commentContent.innerHTML = nl2br(originalMessage);
          messageInput.value = originalMessage;
          comment.classList.remove('is-editable');
          comment.classList.remove('is-showing-preview');
          editCommentForm.classList.remove('is-showing-preview');
        })
      }
    }
  });

  let attachEmojiControls = function(container) {
    let messageInput = container.querySelector('textarea');
    let emojiContainer = container.querySelector('.comment-emojis');
    let currentCategory = container.querySelector('.comment-emojis__category.is-active');
    let idPrefix = emojiContainer.id + '_';

    let selectEmojiCategory = function(id) {
      let clickedCategory = document.getElementById(idPrefix + 'emoji-category-' + id);
      let currentId = parseInt(currentCategory.getAttribute('data-category-id'));

      currentCategory.classList.remove('is-active');
      currentCategory.classList.add('is-left');

      if (id > currentId) {
        for (let i = currentId; i < id; i++) {
          let category = document.getElementById(idPrefix + 'emoji-category-' + i);

          category.classList.remove('is-right');
          category.classList.add('is-left');
        }

        clickedCategory.classList.remove('is-right');
      } else {
        for (let i = id + 1; i <= currentId; i++) {
          let category = document.getElementById(idPrefix + 'emoji-category-' + i);

          category.classList.remove('is-left');
          category.classList.add('is-right');
        }

        clickedCategory.classList.remove('is-left');
      }
    };

    let updateCaretPosition = function(newContent) {
      let selectionStart = messageInput.selectionStart;
      let selectionEnd = messageInput.selectionEnd;
      let content = messageInput.value;
      let length = content.length;

      messageInput.value =
        content.substring(0, selectionStart)
        + newContent
        + content.substring(selectionEnd, length);

      messageInput.focus();

      let caretPosition = selectionStart + (messageInput.value.length - length);
      messageInput.setSelectionRange(caretPosition, caretPosition);
    };

    emojiContainer.querySelectorAll('.comment-emojis__nav-list-entry .comment-emojis__emoji').forEach((navEntry) => {
      navEntry.addEventListener('click', (evt) => {
        evt.preventDefault();

        let clickedCategory = document.getElementById(idPrefix + 'emoji-category-' + navEntry.getAttribute('data-category-id'));

        if (currentCategory === clickedCategory) {
          emojiContainer.classList.toggle('is-open');
        } else {
          emojiContainer.classList.add('is-open');

          currentCategory.classList.remove('is-active');
          currentCategory.classList.add('is-left');

          selectEmojiCategory(parseInt(clickedCategory.getAttribute('data-category-id')));

          clickedCategory.classList.add('is-active');
          currentCategory = clickedCategory;
        }
      })
    });

    emojiContainer.querySelectorAll('.comment-emojis__category-list-entry .comment-emojis__emoji').forEach((emoji) => {
      emoji.addEventListener('click', (evt) => {
        evt.preventDefault();
        updateCaretPosition(emoji.textContent);
      })
    });

    emojiContainer.querySelectorAll('.comment-emojis__emoji').forEach((emoji) => {
      emoji.addEventListener('dragstart', (evt) => {
        evt.dataTransfer.effectAllowed = 'copy';
        evt.dataTransfer.setData('text', emoji.textContent);
      });

      emoji.addEventListener('dragover', (evt) => {
      });

      emoji.addEventListener('dragend', (evt) => {
        let caretPosition = messageInput.value.length + evt.dataTransfer.getData('text').length;
        messageInput.setSelectionRange(caretPosition, caretPosition);
      })
    });

    messageInput.addEventListener('drop', (evt) => {
    });
  };

  document.querySelectorAll('.comment-form').forEach((commentForm) => {
    let previewButton = commentForm.querySelector('.comment-form__form-preview');
    let editPreviewButton = commentForm.querySelector('.comment-form__form-edit-preview');
    let previewCommentContent = commentForm.querySelector('.comment-form__preview .comment__message');
    let messageInput = commentForm.querySelector('.comment-form__form-message textarea');

    if (previewButton) {
      previewButton.addEventListener('click', () => {
        previewCommentContent.innerHTML = nl2br(messageInput.value);
        commentForm.classList.add('is-showing-preview');
      });
    }

    if (editPreviewButton) {
      editPreviewButton.addEventListener('click', () => {
        commentForm.classList.remove('is-showing-preview');
      });
    }

    attachEmojiControls(commentForm);
  });

  document.querySelectorAll('.edit-comment-form').forEach(attachEmojiControls);
};

document.addEventListener('DOMContentLoaded', initComments);