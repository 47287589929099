class AnchorNavigation {
	static init(el) {
		return new AnchorNavigation(el);
	}

	constructor(el) {
		this.el = el;

		this.attachListeners();
	}

	attachListeners() {
		this.el.querySelector('.anchor-navigation__control').addEventListener('click', () => {
			this.el.classList.toggle('is-open');
		});
	}
}
