class LightboxDialog {
	static init(el) {
		return new LightboxDialog(el);
	}

	constructor(el) {
		this.el = el;
		this.contentEl = this.el.querySelector('.dialog__content');
		this.imageEl = this.el.querySelector('.dialog__image');
		this.dialog = new A11yDialog(this.el);
		this.imageLinks = Array.from(document.querySelectorAll('.tag_image .lightbox'));

		this.attachListeners();
	}

	attachListeners() {
		this.imageLinks.forEach(imageLinkEl => {
			const clickedImage = imageLinkEl.querySelector('img');

			imageLinkEl.addEventListener('click', evt => {
				evt.preventDefault();

				this.imageEl.src = imageLinkEl.href;
				this.contentEl.style.width = clickedImage.dataset.width + 'px';
				this.contentEl.style.height = clickedImage.dataset.height + 'px';

				this.dialog.show();
			});
		})
	}
}
