function Slider(parent, opts) {
	this._init(parent, opts);
}

Slider.prototype.DEFAULT_OPTIONS = {
	slideSelector: '*',
	visibleElements: 1,
	animateVisibleElementsChange: false,
	elementSpacingPx: 0,
	// allowed values: default, infinite, repeating
	mode: 'default',
  autoplay: {
    enabled: false,
    interval: 2.5
  },
	swipe: {
		enabled: false,
		ratio: 1,
		throwing: false,
		proxies: null
	},
	animation: {
		enabled: true,
		duration: 0.5,
		force3D: true,
		ease: Back.easeOut
	},
	directionalNav: {
		enabled: true,
		alignment: 'middle',
		offset: undefined
	},
	pagination: {
		enabled: false,
		controllable: true,
		type: 'dots',
		alignment: 'below'
	},
	equalizeHeight: {
		enabled: false,
		fn: function() {}
	},
	onSelectionChangedCbk: function() {}
};

Slider.prototype._init = function(parent, opts) {
	this._parent = parent;
	this._currentProgress = 0;
	this._currentIdx = 0;
	this._currentCloneNo = 0;
	this._navLocked = false;
	this._slideWidthInPercent = 100;
	this._slideWidthInPx = this._parent.offsetWidth;
	this._options = opts || {};

	var waitForFinalEvent = window.waitForFinalEvent || (function() {
		var timers = {};

		return function(callback, ms, uniqueId) {
			if (!uniqueId) {
				uniqueId = "Don't call this twice without a uniqueId";
			}

			if (timers[uniqueId]) {
				clearTimeout(timers[uniqueId]);
			}

			timers[uniqueId] = setTimeout(callback, ms);
		};
	}());

	var extendOptions = function(obj, defaultObj) {
		for (var opt in defaultObj) {
			if (typeof obj[opt] === 'undefined') {
				obj[opt] = defaultObj[opt];
			} else if (typeof defaultObj[opt] === 'object') {
				extendOptions(obj[opt], defaultObj[opt]);
			}
		}
	};

	extendOptions(this._options, this.DEFAULT_OPTIONS);

	if (this._options.mode === 'infinite' && this._options.pagination.enabled && this._options.pagination.type === 'pages') {
		throw "Invalid pagination type (\"pages\") for \"infinite\" mode.";
	}

	if (this._options.directionalNav.enabled) {
		this._directionalNav = {
			container: document.createElement('div')
		};
	}

	this._initDOM();
	this._setSlideWidth();

	if (this._options.mode === 'infinite') {
		this._initClones();
	} else {
		this._numClones = 0;
	}

	if (this._options.swipe.enabled) {
		this._initSwipe();
	}

	if (this._options.directionalNav.enabled) {
		this._slider.classList.add('directional-nav-' + this._options.directionalNav.alignment);
		this._initDirectionalNav();
	}

	if (this._options.pagination.enabled) {
		this._slider.classList.add('pagination-' + this._options.pagination.alignment);
		this._initPagination();
	}

	if (this._options.directionalNav.enabled) {
		this.directionalNavOffset(this._options.directionalNav.offset);
	}

	if (this._options.visibleElements === this._numSlides) {
		if (this._options.pagination.enabled) {
			this._pagination[this._options.pagination.type].container.classList.add('hidden');
		}

		this._directionalNav.container.classList.add('hidden');
	}

	if (this._options.mode === 'infinite') {
		this._setToIdx(this._numClones);
	}

	this._lastWindowOuterWidth = window.outerWidth;
	this._id = Math.random();
	window.addEventListener('resize', function() {
		waitForFinalEvent(this._onWindowResize.bind(this), 500, "rwd-slider-" + this._id);
	}.bind(this));

  if (this._options.autoplay.enabled) {
    new TimelineMax({
     repeat: -1
    }).call(this.selectNext, [], this, this._options.autoplay.interval);
  }
};

Slider.prototype._onWindowResize = function() {
	var newWindowOuterWidth = window.outerWidth;

	if (this._attached && this._lastWindowOuterWidth !== newWindowOuterWidth) {
		this._lastWindowOuterWidth = newWindowOuterWidth;
		this._setSlideWidth();

		if (this._options.swipe.enabled) {
			this._updateSwipeBounds();
		}

		this._setToIdx(this._currentIdx + this._numClones);
	}
};

Slider.prototype._initSwipe = function() {
	var initSwipeProxy = function(container) {
		container.classList.add('swipe-trigger');
		var hammer = new Hammer(container);
		hammer.on('panstart', this._onPanStart.bind(this));
		hammer.on('panmove', this._onPanMove.bind(this));
		hammer.on('panend', this._onPanEnd.bind(this));
		hammer.get('pan').set({
			direction: Hammer.DIRECTION_HORIZONTAL,
			threshold: 0,
			pointers: 0
		});

		this._hammers.push(hammer);
	}.bind(this);

	if (this._options.swipe.proxies === null) {
		this._options.swipe.proxies = [];
		this._options.swipe.proxies.push(this._slider);
	}

	this._hammers = [];
	this._options.swipe.proxies.forEach(initSwipeProxy);

	if (this._options.mode === 'infinite') {
		this._totalSwipe = -this._numClones * this._slideWidthInPx;
	} else {
		this._totalSwipe = 0;
	}

	this._updateSwipeBounds();
};

Slider.prototype._onPanStart = function(evt) {
	// TODO: Schönere Lösung?
	// Problem:
	// Ist der Wrapper gerade im "Wurf", so kann die Pfeil-Navigation genutzt werden, bevor der Wrapper umgesetzt wurde
	// (bei Erreichen von maximal sichtbarer Anzahl Clones).
	//this._navLocked = true;
	//this._lockNav();

	this._swipeDistance = evt.deltaX;
	//this._totalSwipe = this._totalSwipe - (this._options.swipe.ratio * (this._swipeDistance - evt.deltaX));

	if (this._slidesWrapper._gsTransform !== undefined) {
		this._totalSwipe = this._slidesWrapper._gsTransform.x - (this._options.swipe.ratio * (this._swipeDistance - evt.deltaX));

		if (this._options.mode === 'infinite') {
			TweenLite.set(this._slidesWrapper, {x: this._totalSwipe});
			this._swipeDistance = evt.deltaX;
		} else {
			if (this._totalSwipe <= this._maxSwipe && this._totalSwipe >= this._minSwipe) {
				TweenLite.set(this._slidesWrapper, {x: this._totalSwipe});
				this._swipeDistance = evt.deltaX;
			} else {
				if (this._totalSwipe > this._maxSwipe) {
					this._totalSwipe = this._maxSwipe;
				} else if (this._totalSwipe < this._minSwipe) {
					this._totalSwipe = this._minSwipe;
				}
			}
		}
	}
};

Slider.prototype._onPanEnd = function(evt) {
	if (this._options.pagination.enabled && this._options.pagination.type === 'pages') {
		this._totalSwipe = Math.round(this._totalSwipe / this._slideWidthInPx) * this._slideWidthInPx * this._options.visibleElements;
	} else {
		this._totalSwipe = Math.round(this._totalSwipe / this._slideWidthInPx) * this._slideWidthInPx;
	}

	if (this._options.swipe.throwing) {
		ThrowPropsPlugin.to(this._slidesWrapper, {
			throwProps: {
				x: {
					// Hammer.js velocity is px/ms, ThrowPropsPlugin needs px/s
					velocity: -evt.velocityX * 1000,
					max: this._maxSwipe,
					min: this._minSwipe,
					end: function(val) {
						if (this._options.pagination.enabled && this._options.pagination.type === 'pages') {
							return Math.round(val / this._slideWidthInPx) * this._slideWidthInPx * this._options.visibleElements;
						} else {
							return Math.round(val / this._slideWidthInPx) * this._slideWidthInPx;
						}
					}.bind(this)
				}
			},
			ease: Strong.easeOut,
			force3D: this._options.animation.force3D,
			onUpdateScope: this,
			onUpdate: function() {
				var currentX = this._slidesWrapper._gsTransform.x;

				if (currentX < this._maxSwipe && currentX > this._minSwipe) {
					this._totalSwipe = currentX;
					this._updateIndicesFromTotalSwipe();

					if (this._options.pagination.enabled) {
						this._updatePagination();
					}
				} else if (this._options.mode === 'infinite') {
					var totalRounded = Math.round(this._totalSwipe);

					if (totalRounded >= this._maxSwipe) {
						this._lockNav();
						this._totalSwipe = -(this._numSlides + this._numClones - this._options.visibleElements) * this._slideWidthInPx;
						TweenLite.set(this._slidesWrapper, {
							x: this._totalSwipe,
							onCompleteScope: this,
							onComplete: function() {
								this._totalSwipe = this._slidesWrapper._gsTransform.x;
								this._updateIndicesFromTotalSwipe();
								this._unlockNav();
							}
						});
						this._swipeDistance = evt.deltaX;
					} else if (totalRounded <= this._minSwipe) {
						this._lockNav();
						this._totalSwipe = -this._numClones * this._slideWidthInPx;
						TweenLite.set(this._slidesWrapper, {
							x: this._totalSwipe,
							onCompleteScope: this,
							onComplete: function() {
								this._totalSwipe = this._slidesWrapper._gsTransform.x;
								this._updateIndicesFromTotalSwipe();
								this._unlockNav();
							}
						});
						this._swipeDistance = evt.deltaX;
					}
				}
			},
			onCompleteScope: this,
			onComplete: function() {
				this._totalSwipe = this._slidesWrapper._gsTransform.x;

				if (this._options.mode === 'infinite') {
					var totalRounded = Math.round(this._totalSwipe);

					if (totalRounded === this._maxSwipe) {
						this._totalSwipe = -(this._numSlides + this._numClones - this._options.visibleElements) * this._slideWidthInPx;
						TweenLite.set(this._slidesWrapper, {
							x: this._totalSwipe,
							onCompleteScope: this,
							onComplete: function() {
								this._totalSwipe = this._slidesWrapper._gsTransform.x;
								this._updateIndicesFromTotalSwipe();
								this._unlockNav();
							}
						});
						this._swipeDistance = evt.deltaX;
					} else if (totalRounded === this._minSwipe) {
						this._totalSwipe = -this._numClones * this._slideWidthInPx;
						TweenLite.set(this._slidesWrapper, {
							x: this._totalSwipe,
							onCompleteScope: this,
							onComplete: function() {
								this._totalSwipe = this._slidesWrapper._gsTransform.x;
								this._updateIndicesFromTotalSwipe();
								this._unlockNav();
							}
						});
						this._swipeDistance = evt.deltaX;
					}
				} else {
					this._updateIndicesFromTotalSwipe();
				}

				// TODO: Schönere Lösung?
				//this._navLocked = false;
				//this._unlockNav();
			}
		}, 2, 0.5, 0.5);
	} else {
		TweenLite.to(this._slidesWrapper, this._options.animation.duration * 2, {
			x: this._totalSwipe,
			ease: Back.easeOut,
			force3D: this._options.animation.force3D,
			onUpdateScope: this,
			onUpdate: function() {
				this._updateIndicesFromTotalSwipe();

				if (this._options.pagination.enabled) {
					this._updatePagination();
				}
			},
			onCompleteScope: this,
			onComplete: function() {
				// TODO: Schönere Lösung?
				//this._navLocked = false;
				//this._unlockNav();
			}
		});
	}
};

Slider.prototype._onPanMove = function(evt) {
	this._totalSwipe = this._totalSwipe - (this._options.swipe.ratio * (this._swipeDistance - evt.deltaX));

	if (this._options.mode === 'infinite') {
		if (this._totalSwipe < this._maxSwipe && this._totalSwipe > this._minSwipe) {
			TweenLite.set(this._slidesWrapper, {x: this._totalSwipe});
			this._swipeDistance = evt.deltaX;
		} else {
			if (this._totalSwipe >= this._maxSwipe) {
				this._lockNav();
				var delta = this._totalSwipe - this._maxSwipe;
				this._totalSwipe = -(this._numSlides + this._numClones - this._options.visibleElements) * this._slideWidthInPx + delta;
				TweenLite.set(this._slidesWrapper, {x: this._totalSwipe, onComplete: this._unlockNav.bind(this)});
				this._swipeDistance = evt.deltaX;
			} else if (this._totalSwipe <= this._minSwipe) {
				this._lockNav();
				var delta = this._minSwipe - this._totalSwipe;
				this._totalSwipe = -this._numClones * this._slideWidthInPx - delta;
				TweenLite.set(this._slidesWrapper, {x: this._totalSwipe, onComplete: this._unlockNav.bind(this)});
				this._swipeDistance = evt.deltaX;
			}
		}
	} else {
		if (this._totalSwipe <= this._maxSwipe && this._totalSwipe >= this._minSwipe) {
			TweenLite.set(this._slidesWrapper, {x: this._totalSwipe});
			this._swipeDistance = evt.deltaX;
		} else {
			if (this._totalSwipe > this._maxSwipe) {
				this._totalSwipe = this._maxSwipe;
			} else if (this._totalSwipe < this._minSwipe) {
				this._totalSwipe = this._minSwipe;
			}
		}
	}

	this._updateIndicesFromTotalSwipe();

	if (this._options.pagination.enabled) {
		this._updatePagination();
	}
};

Slider.prototype._updateSwipeBounds = function() {
	this._maxSwipe = 0;

	if (this._options.mode === 'infinite') {
		this._minSwipe = -(this._slideWidthInPx * (this._numSlides - this._options.visibleElements + this._numClones * 2));
	} else {
		this._minSwipe = -(this._slideWidthInPx * (this._numSlides - this._options.visibleElements));
	}
};

Slider.prototype._setSlideWidth = function() {
	this._slideWidthInPercent = 100 / this._options.visibleElements;

	this._slides.forEach(function(slide) {
		if (this._options.animateVisibleElementsChange) {

		} else {
			TweenLite.set(slide, {width: this._slideWidthInPercent + '%'});
		}
	}.bind(this));

	if (this._options.swipe.enabled) {
		var numSwipedElements = this._totalSwipe / this._slideWidthInPx;
	}

	this._slideWidthInPx = this._slidesWrapper.offsetWidth / this._options.visibleElements;

	if (this._options.swipe.enabled) {
		if (this._options.mode === 'infinite') {
			var numNewClones = this._options.visibleElements - this._numVisibleElementsLast;
			this._totalSwipe = (numSwipedElements - numNewClones) * this._slideWidthInPx;
		} else {
			this._totalSwipe = numSwipedElements * this._slideWidthInPx;
		}
	}
};

Slider.prototype._initDOM = function() {
	this._slider = document.createElement('div');
	this._slider.classList.add('slider');
	this._parent.appendChild(this._slider);

	if (typeof this._viewport !== 'undefined') {
		this._viewport.remove();
	}

	this._viewport = document.createElement('div');
	this._viewport.classList.add('viewport');
	this._slider.appendChild(this._viewport);

	if (typeof this._slidesWrapper !== 'undefined') {
		this._slidesWrapper.remove();
	}

	this._slidesWrapper = document.createElement('div');
	this._slidesWrapper.classList.add('slides-wrapper');
	this._viewport.appendChild(this._slidesWrapper);

	this._slides = [];
	var slideNodes = this._parent.querySelectorAll(this._options.slideSelector);

	Array.prototype.slice.call(slideNodes).forEach(function(element) {
		var li = document.createElement('li');
		li.classList.add('slide');
		li.style.paddingLeft = (this._options.elementSpacingPx / 2) + 'px';
		li.style.paddingRight = (this._options.elementSpacingPx / 2) + 'px';
		li.appendChild(element);
		this._slidesWrapper.appendChild(li);
		this._slides.push(li);
	}.bind(this));

	this._numSlides = this._slides.length;
	this._attached = true;
};

Slider.prototype._initClones = function() {
	if (typeof this._clonesBeginning !== 'undefined' && typeof this._clonesEnd !== 'undefined') {
		this._clonesBeginning.forEach(function(clone) {
			this._slidesWrapper.removeChild(clone);
		}.bind(this));

		this._clonesEnd.forEach(function(clone) {
			this._slidesWrapper.removeChild(clone);
		}.bind(this));
	}

	this._clonesBeginning = [];
	this._clonesEnd = [];
	var previousElement = this._slides[0];

	for (var i = 0; i < this._options.visibleElements; i++) {
		var cloneBeginning = this._slides[this._numSlides - 1 - i].cloneNode(true);
		cloneBeginning.classList.add('clone');
		this._slidesWrapper.insertBefore(cloneBeginning, previousElement);
		previousElement = cloneBeginning;
		this._clonesBeginning[this._options.visibleElements - 1 - i] = cloneBeginning;
	}

	for (var i = 0; i < this._options.visibleElements; i++) {
		var cloneEnd = this._slides[i].cloneNode(true);
		cloneEnd.classList.add('clone');
		this._slidesWrapper.appendChild(cloneEnd);
		this._clonesEnd[i] = cloneEnd;
	}

	this._numClones = this._clonesBeginning.length;
};

Slider.prototype._initPagination = function() {
	if (typeof this._pagination === 'undefined') {
		this._pagination = [];
	}

	if (typeof this._pagination[this._options.pagination.type] === 'undefined') {
		this._pagination[this._options.pagination.type] = [];
		this._pagination[this._options.pagination.type].container = document.createElement('div');
		this._pagination[this._options.pagination.type].container.classList.add('pagination');
		this._pagination[this._options.pagination.type].container.classList.add(this._options.pagination.alignment);
		this._slider.appendChild(this._pagination[this._options.pagination.type].container);

		if (this._options.pagination.type === 'dots') {
			var dots = document.createElement('ul');
			dots.classList.add('dots');
			this._pagination.dots.container.appendChild(dots);

			this._pagination.dots.dots = this._slides.map(function(slide, i) {
				var dot = document.createElement('li');
				dot.classList.add('dot');

				if (this._options.pagination.controllable) {
					dot.addEventListener('click', function(idx) {
						return function() {
							if (!this._navLocked) {
								this.selectSlide(idx);
							}
						}.bind(this);
					}.bind(this)(i));
					dot.classList.add('controllable');
				}
				dots.appendChild(dot);

				return dot;
			}.bind(this));
		} else if (this._options.pagination.type === 'bar') {
			var bar = document.createElement('div');
			bar.classList.add('bar');
			this._pagination.bar.container.appendChild(bar);

			this._pagination.bar.progressBar = document.createElement('div');
			this._pagination.bar.progressBar.classList.add('progress');
			bar.appendChild(this._pagination.bar.progressBar);

			if (this._options.mode === 'infinite') {
				this._pagination.bar.progressBarInfinite = document.createElement('div');
				this._pagination.bar.progressBarInfinite.classList.add('progress');
				bar.appendChild(this._pagination.bar.progressBarInfinite);
			}
		} else if (this._options.pagination.type === 'pages') {
			this._updatePagesDOM();
		}
	} else {
		this._slider.appendChild(this._pagination[this._options.pagination.type].container);
	}

	//if (this._options.pagination.type === 'pages') {
	//  this._updatePagesDOM();
	//  this.selectSlide(this._pagination['pages'].idxPages[this._currentIdx + this._options.visibleElements - 1].slideIdxStart);
	//}

	this._updatePagination();
};

Slider.prototype._updatePagesDOM = function() {
	if (typeof this._pagination.pages.pagesWrapper !== 'undefined') {
		this._pagination.pages.pagesWrapper.remove();
	}

	this._pagination.pages.pagesWrapper = document.createElement('ul');
	this._pagination.pages.pagesWrapper.classList.add('pages');
	this._pagination.pages.container.appendChild(this._pagination.pages.pagesWrapper);

	this._pagination.pages.pages = [];
	this._pagination.pages.idxPages = [];
	for (var i = 0; i < this._numSlides; i += this._options.visibleElements) {
		var page = {
			element: document.createElement('li'),
			slideIdxStart: i,
			slideIdxEnd: i + this._options.visibleElements < this._numSlides ? i + this._options.visibleElements - 1 : this._numSlides - 1
		};
		page.element.classList.add('page');
		page.element.innerHTML = (i / this._options.visibleElements + 1);

		if (this._options.pagination.controllable) {
			page.element.addEventListener('click', function(page) {
				return function() {
					this.selectSlide(page.slideIdxStart);
				}.bind(this);
			}.bind(this)(page));
			page.element.classList.add('controllable');
		}

		this._pagination.pages.pagesWrapper.appendChild(page.element);
		this._pagination.pages.pages.push(page);
	}

	this._pagination.pages.pages.forEach(function(page) {
		for (var i = page.slideIdxStart; i <= page.slideIdxEnd; i++) {
			this._pagination.pages.idxPages[i] = page;
		}
	}.bind(this));

	this._pagination.pages.currentPage = this._pagination.pages.pages[0];
};

Slider.prototype._updateIndicesFromTotalSwipe = function() {
	var oldIdxRounded = this._currentIdx;

	if (this._options.mode === 'infinite') {
		var totalSwipeNormalized = this._totalSwipe + this._numClones * this._slideWidthInPx;
		this._currentProgress = -(totalSwipeNormalized / this._slideWidthInPx);
	} else {
		this._currentProgress = Math.abs(this._totalSwipe / this._slideWidthInPx);
	}

	this._currentIdx = Math.round(this._currentProgress);
	this._fireSelectionChanged(oldIdxRounded, this._currentIdx);

	if (this._options.mode === 'infinite') {
		if (this._currentIdx + this._options.visibleElements >= this._numSlides) {
			this._currentCloneNo = this._currentIdx + this._options.visibleElements - this._numSlides;
		} else if (this._currentIdx < 0) {
			this._currentCloneNo = this._currentIdx;
		} else {
			this._currentCloneNo = 0;
		}
	}
};

Slider.prototype._updatePagination = function() {
	if (this._options.pagination.type === 'dots') {
		this._pagination.dots.dots.forEach(function(dot) {
			dot.classList.remove('active');
			dot.classList.remove('primary');
		});

		if (this._options.visibleElements > 1) {
			if (this._options.mode === 'infinite' && this._currentCloneNo !== 0) {
				if (this._currentCloneNo < 0) {
					// currentIdx := currentCloneNo
					for (var i = this._numSlides - 1; i >= this._numSlides + this._currentCloneNo; i--) {
						this._pagination.dots.dots[i].classList.add('active');
					}

					for (var i = 0; i <= this._currentCloneNo + this._options.visibleElements - 1; i++) {
						this._pagination.dots.dots[i].classList.add('active');
					}

					this._pagination.dots.dots[this._numSlides + this._currentCloneNo].classList.add('primary');
				} else {
					// (numSlides - currentCloneNo <= currentIdx < numSlides + currentCloneNo
					for (var i = 0; i < this._currentCloneNo; i++) {
						this._pagination.dots.dots[i].classList.add('active');
					}

					if (this._currentIdx === this._numSlides) {
						// currentIdx := numSlides
						this._pagination.dots.dots[0].classList.add('active');
						this._pagination.dots.dots[0].classList.add('primary');
					} else if (this._currentIdx > this._numSlides) {
						// (numSlides < currentIdx < numSlides + currentCloneNo)
						this._pagination.dots.dots[this._currentCloneNo - 1].classList.add('primary');
					} else {
						// (numSlides - currentCloneNo <= currentIdx < numSlides)
						for (var i = this._currentIdx; i < this._numSlides; i++) {
							this._pagination.dots.dots[i].classList.add('active');
						}

						this._pagination.dots.dots[this._currentIdx].classList.add('primary');
					}
				}
			} else {
				for (var i = this._currentIdx + 1; i < this._currentIdx + this._options.visibleElements; i++) {
					this._pagination.dots.dots[i].classList.add('active');
					this._pagination.dots.dots[i].classList.add('secondary');
				}

				// (0 <= currentIdx < numSlides)
				this._pagination.dots.dots[this._currentIdx].classList.add('active');
				this._pagination.dots.dots[this._currentIdx].classList.add('primary');
			}
		} else {
			// numSlides := 1
			if (this._options.mode === 'infinite' && this._currentCloneNo !== 0) {
				if (this._currentCloneNo > 0) {
					// currentCloneNo := 1
					this._pagination.dots.dots[this._currentCloneNo - 1].classList.add('active');
					this._pagination.dots.dots[this._currentCloneNo - 1].classList.add('primary');
				} else {
					// currentCloneNo := -1
					this._pagination.dots.dots[this._numSlides + this._currentCloneNo].classList.add('active');
					this._pagination.dots.dots[this._numSlides + this._currentCloneNo].classList.add('primary');
				}
			} else {
				// (0 <= currentIdx < numSlides)
				this._pagination.dots.dots[this._currentIdx].classList.add('active');
				this._pagination.dots.dots[this._currentIdx].classList.add('primary');
			}
		}
	} else if (this._options.pagination.type === 'bar') {
		if (this._options.mode === 'infinite') {
			if (this._currentProgress < 0 || this._currentProgress + this._options.visibleElements > this._numSlides) {
				var exceedance;

				if (this._currentProgress + this._options.visibleElements > this._numSlides) {
					exceedance = this._currentProgress + this._options.visibleElements - this._numSlides;

					TweenLite.set(this._pagination.bar.progressBar, {
						left: (this._currentProgress / this._numSlides * 100) + '%',
						width: (100 - (this._currentProgress / this._numSlides * 100)) + '%'
					});

					TweenLite.set(this._pagination.bar.progressBarInfinite, {
						left: 0,
						width: (exceedance / this._numSlides * 100) + '%'
					});
				} else {
					exceedance = this._currentProgress;

					TweenLite.set(this._pagination.bar.progressBar, {
						left: 0,
						width: ((this._options.visibleElements + exceedance) / this._numSlides * 100) + '%'
					});

					TweenLite.set(this._pagination.bar.progressBarInfinite, {
						left: ((this._numSlides + exceedance) / this._numSlides * 100) + '%',
						width: (100 - ((this._numSlides + exceedance) / this._numSlides * 100)) + '%'
					});
				}
			} else {
				TweenLite.set([this._pagination.bar.progressBar, this._pagination['bar'].progressBarInfinite], {
					left: (this._currentProgress / this._numSlides * 100) + '%',
					width: (this._options.visibleElements / this._numSlides * 100) + '%'
				});
			}
		} else {
			TweenLite.set(this._pagination.bar.progressBar, {
				left: (this._currentProgress / this._numSlides * 100) + '%',
				width: (this._options.visibleElements / this._numSlides * 100) + '%'
			});
		}
	} else if (this._options.pagination.type === 'pages') {
		this._pagination.pages.pages.forEach(function(page) {
			page.element.classList.remove('selected');
		});

		this._pagination.pages.idxPages[this._currentIdx + this._options.visibleElements - 1].element.classList.add('selected');
	}
};

Slider.prototype._initDirectionalNav = function() {
	this._slider.appendChild(this._directionalNav.container);

	this._directionalNav.container.classList.add('directional-nav');
	this._directionalNav.container.classList.add(this._options.directionalNav.alignment);

	this._directionalNav.btnNextSlide = document.createElement('div');
	this._directionalNav.btnNextSlide.classList.add('next');
	this._directionalNav.btnNextSlide.addEventListener('click', function() {
		if (!this._navLocked) {
			this.selectNext();
		}
	}.bind(this));

	this._directionalNav.btnPreviousSlide = document.createElement('div');
	this._directionalNav.btnPreviousSlide.classList.add('previous');
	this._directionalNav.btnPreviousSlide.addEventListener('click', function() {
		if (!this._navLocked) {
			this.selectPrevious();
		}
	}.bind(this));

	this._directionalNav.container.appendChild(this._directionalNav.btnPreviousSlide);
	this._directionalNav.container.appendChild(this._directionalNav.btnNextSlide);
};

Slider.prototype._fireSelectionChanged = function(oldIdx, newIdx) {
	if (oldIdx !== newIdx) {
		var oldIdxReal;
		var newIdxReal;

		if (this._options.mode === 'infinite') {
			oldIdxReal = function() {
				if (oldIdx < 0) {
					return this._numSlides + oldIdx;
				} else if (oldIdx >= this._numSlides) {
					return oldIdx - this._numSlides;
				} else {
					return oldIdx;
				}
			}.bind(this)();
			newIdxReal = function() {
				if (newIdx < 0) {
					return this._numSlides + newIdx;
				} else if (newIdx >= this._numSlides) {
					return newIdx - this._numSlides;
				} else {
					return newIdx;
				}
			}.bind(this)();
		} else {
			oldIdxReal = oldIdx;
			newIdxReal = newIdx;
		}

		var maxVisible = function(idx) {
			return idx >= this._numSlides ? this._numSlides - 1 : idx;
		}.bind(this);

		var oldMaxVisible = maxVisible(oldIdxReal + this._options.visibleElements - 1);
		var newMaxVisible = maxVisible(newIdxReal + this._options.visibleElements - 1);

		this._options.onSelectionChangedCbk(
			this._slides.slice(oldIdxReal, oldMaxVisible + 1).map(function(slide) {
				return slide.firstElementChild;
			}),
			this._slides.slice(newIdxReal, newMaxVisible + 1).map(function(slide) {
				return slide.firstElementChild;
			}),
			oldIdxReal, newIdxReal
		);
	}
};

Slider.prototype._setToIdx = function(idx, cbk) {
	// idx wird hier einfach als multiplikator verwendet.
	// bei infinite mode darauf achten, dass clones berücksichtigt werden
	TweenLite.set(this._slidesWrapper, {
		x: -idx * this._slideWidthInPx,
		onComplete: function() {
			if (typeof cbk === 'function') {
				cbk();
			}
		}
	});
};

Slider.prototype._lockNav = function() {
	this._navLocked = true;

	if (this._options.pagination.enabled) {
		this._pagination[this._options.pagination.type].container.classList.add('locked');
	}

	if (this._options.directionalNav.enabled) {
		this._directionalNav.container.classList.add('locked');
	}
};

Slider.prototype._unlockNav = function() {
	this._navLocked = false;

	if (this._options.pagination.enabled) {
		this._pagination[this._options.pagination.type].container.classList.remove('locked');
	}

	if (this._options.directionalNav.enabled) {
		this._directionalNav.container.classList.remove('locked');
	}
};

Slider.prototype._animateToIdx = function(idx, cbk) {
	// idx wird hier einfach als multiplikator verwendet.
	// bei infinite mode darauf achten, dass clones berücksichtigt werden
	TweenLite.to(this._slidesWrapper, this._options.animation.duration, {
		x: -idx * this._slideWidthInPx,
		ease: this._options.animation.ease,
		force3D: this._options.animation.force3D,
		onUpdateScope: this,
		onUpdate: function() {
			if (this._options.pagination.enabled) {
				this._updatePagination();
			}
		},
		onComplete: function() {
			if (typeof cbk === 'function') {
				cbk();
			}
		}
	});
};

Slider.prototype.selectSlide = function(idx) {
	var newIdx = idx;
	var oldIdx = this._currentIdx;

	if (newIdx !== this._currentIdx) {
		if (newIdx + this._options.visibleElements >= this._numSlides && this._options.mode !== 'infinite') {
			newIdx = this._numSlides - this._options.visibleElements;
		}

		if (this._options.mode === 'infinite') {
			this._currentCloneNo = newIdx + this._options.visibleElements > this._numSlides
				? Math.abs(this._numSlides - (newIdx + this._options.visibleElements))
				:  newIdx < 0
				? newIdx
				: 0;

			if (Math.abs(this._currentCloneNo) >= this._numClones) {
				var destIdx;

				if (this._currentCloneNo < 0) {
					destIdx = this._numSlides - this._options.visibleElements;
				} else {
					destIdx = 0;
				}

				this._currentProgress = destIdx;
				this._currentIdx = destIdx;
				this._currentCloneNo = 0;
				this._lockNav();
				destIdx = destIdx + this._numClones;

				if (this._options.animation.enabled) {
					this._animateToIdx(newIdx + this._numClones, function() {
						this._setToIdx(destIdx, this._unlockNav.bind(this));
					}.bind(this));
				} else {
					this._setToIdx(newIdx + this._numClones, function() {
						this._setToIdx(destIdx, this._unlockNav.bind(this));
					}.bind(this));
				}
			} else {
				this._currentProgress = newIdx;
				this._currentIdx = newIdx;

				if (this._options.animation.enabled) {
					this._animateToIdx(newIdx + this._numClones);
				} else {
					this._setToIdx(newIdx + this._numClones);
				}
			}
		} else {
			if (this._options.animation.enabled) {
				this._animateToIdx(newIdx);
			} else {
				this._setToIdx(newIdx);
			}

			this._currentProgress = newIdx;
			this._currentIdx = newIdx;
		}

		this._fireSelectionChanged(oldIdx, newIdx);

		if (this._options.swipe.enabled) {
			this._totalSwipe = this._totalSwipe + (this._currentProgress - idx) * this._slideWidthInPx;
		}
	}
};

Slider.prototype.selectNext = function() {
	var newIdx = function() {
		if (this._options.pagination.enabled && this._options.pagination.type === 'pages') {
			return function() {
				var maxVisibleIdx = this._currentIdx + this._options.visibleElements - 1;
				var nextIdx = this._pagination.pages.idxPages[maxVisibleIdx].slideIdxEnd + 1;

				if (nextIdx >= this._pagination.pages.idxPages.length && this._options.mode === 'repeating') {
					nextIdx = 0;
				}

				return nextIdx;
			}.bind(this)();
		} else {
			if (this._options.mode === 'repeating') {
				return this._currentIdx + 1 > this._numSlides - this._options.visibleElements ? 0 : this._currentIdx + 1;
			} else if (this._options.mode === 'infinite') {
				return this._currentIdx + 1;
			} else {
				return this._currentIdx + 1 > this._numSlides - this._options.visibleElements ? this._currentIdx : this._currentIdx + 1;
			}
		}
	}.bind(this)();

	this.selectSlide(newIdx);
};

Slider.prototype.selectPrevious = function() {
	var previousSlide = function() {
		return this._currentIdx - 1 < 0
			? this._options.mode === 'repeating' ? this._numSlides - this._options.visibleElements : this._currentIdx
			: this._currentIdx - 1;
	}.bind(this)();

	var newIdx = function() {
		if (this._options.pagination.enabled && this._options.pagination.type === 'pages') {
			var previousIdx = this._currentIdx - this._options.visibleElements;

			return previousIdx >= 0 ? previousIdx : previousSlide;
		} else {
			if (this._options.mode === 'infinite') {
				return this._currentIdx - 1;
			} else {
				return previousSlide;
			}
		}
	}.bind(this)();

	this.selectSlide(newIdx);
};

Slider.prototype.setVisibleElements = function(numElements) {
	if (numElements > 0 && numElements <= this._numSlides) {
		if (numElements > this._options.visibleElements
			&& (this._currentIdx + numElements) >= this._numSlides) {
			this._currentProgress = this._numSlides - numElements;
			this._currentIdx = this._currentProgress;
		}

		if (this._options.visibleElements === this._numSlides) {
			if (this._options.pagination.enabled) {
				this._pagination[this._options.pagination.type].container.classList.remove('hidden');
			}
			this._directionalNav.container.classList.remove('hidden');
		}

		this._numVisibleElementsLast = this._options.visibleElements;
		this._options.visibleElements = numElements;
		this._setSlideWidth();

		if (this._options.visibleElements === this._numSlides) {
			if (this._options.pagination.enabled) {
				this._pagination[this._options.pagination.type].container.classList.add('hidden');
			}
			this._directionalNav.container.classList.add('hidden');
		}

		if (this._options.mode === 'infinite') {
			this._initClones();
		}

		if (this._options.swipe.enabled) {
			this._updateSwipeBounds();
		}

		if (this._options.equalizeHeight.enabled) {
			this._options.equalizeHeight.fn();
		}

		if (this._options.mode === 'infinite') {
			this._setToIdx(this._currentIdx + this._clonesBeginning.length);
		} else {
			this._setToIdx(this._currentIdx);
		}

		if (this._options.pagination.enabled) {
			if (this._options.pagination.type === 'pages') {
				this._updatePagesDOM();
				this.selectSlide(this._pagination.pages.idxPages[this._currentIdx + this._options.visibleElements - 1].slideIdxStart);
			}

			this._updatePagination();
		}
	}
};

Slider.prototype.isAttached = function() {
	return this._attached;
};

Slider.prototype.reattach = function() {
	this._parent.appendChild(this._slider);
	this._attached = true;

	this._slides = [];
	var slideNodes = this._parent.querySelectorAll(this._options.slideSelector);
	this._numSlides = slideNodes.length;

	Array.prototype.slice.call(slideNodes).forEach(function(element) {
		var li = document.createElement('li');
		li.classList.add('slide');
		li.style.paddingLeft = (this._options.elementSpacingPx / 2) + 'px';
		li.style.paddingRight = (this._options.elementSpacingPx / 2) + 'px';
		li.appendChild(element);
		this._slidesWrapper.appendChild(li);
		this._slides.push(li);
	}.bind(this));

	this._setSlideWidth();

	if (this._options.swipe.enabled) {
		this._updateSwipeBounds();
	}

	this._setToIdx(this._currentIdx);
};

Slider.prototype.detach = function() {
	this._slider.remove();
	this._attached = false;

	this._slides.forEach(function(slide) {
		this._parent.appendChild(slide.querySelector('*'));
		slide.remove();
	}.bind(this));
};

Slider.prototype.enableSwipe = function() {
	if (this._options.swipe.enabled) {
		this._hammers.forEach(function(hammer) {
			hammer.on('panstart', this._onPanStart.bind(this));
			hammer.on('panmove', this._onPanMove.bind(this));
			hammer.on('panend', this._onPanEnd.bind(this));
			hammer.get('pan').set({
				direction: Hammer.DIRECTION_HORIZONTAL,
				threshold: 0
			});
		}.bind(this));
	}
};

Slider.prototype.disableSwipe = function() {
	if (this._options.swipe.enabled) {
		this._hammers.forEach(function(hammer) {
			hammer.off('panstart');
			hammer.off('panmove');
			hammer.off('panend');
		});
	}
};

Slider.prototype.paginationAlignment = function(newAlignment) {
	if (typeof newAlignment === 'undefined') {
		return this._options.pagination.alignment;
	} else if (this._options.pagination.enabled && newAlignment !== this._options.pagination.alignment) {
		this._pagination[this._options.pagination.type].container.classList.remove(this._options.pagination.alignment);
		this._pagination[this._options.pagination.type].container.classList.add(newAlignment);

		if (this._options.directionalNav.enabled
			&& this._options.directionalNav.alignment === this._options.pagination.alignment) {
			this._pagination[this._options.pagination.type].container.style.removeProperty('padding-right');
			this._pagination[this._options.pagination.type].container.style.removeProperty('padding-left');
		}

		this._options.pagination.alignment = newAlignment;

		if (this._options.directionalNav.enabled
			&& this._options.directionalNav.alignment === this._options.pagination.alignment) {
			TweenLite.set(this._pagination[this._options.pagination.type].container, {
				paddingRight: (this._options.directionalNav.offset + this._directionalNav.btnNextSlide.offsetHeight * 3 / 2) + 'px',
				paddingLeft: (this._options.directionalNav.offset + this._directionalNav.btnPreviousSlide.offsetHeight * 3 / 2) + 'px'
			});
		}
	}
};

Slider.prototype.directionalNavAlignment = function(newAlignment) {
	if (typeof newAlignment === 'undefined') {
		return this._options.directionalNav.alignment;
	} else if (this._options.directionalNav.enabled && newAlignment !== this._options.directionalNav.alignment) {
		this._directionalNav.container.classList.remove(this._options.directionalNav.alignment);
		this._directionalNav.container.classList.add(newAlignment);

		if (this._options.pagination.enabled
			&& this._options.directionalNav.alignment === this._options.pagination.alignment) {
			this._pagination[this._options.pagination.type].container.style.removeProperty('padding-right');
			this._pagination[this._options.pagination.type].container.style.removeProperty('padding-left');
		}

		this._options.directionalNav.alignment = newAlignment;

		if (this._options.pagination.enabled
			&& this._options.directionalNav.alignment === this._options.pagination.alignment) {
			TweenLite.set(this._pagination[this._options.pagination.type].container, {
				paddingRight: (this._options.directionalNav.offset + this._directionalNav.btnNextSlide.offsetHeight * 3 / 2) + 'px',
				paddingLeft: (this._options.directionalNav.offset + this._directionalNav.btnPreviousSlide.offsetHeight * 3 / 2) + 'px'
			});
		}
	}
};

Slider.prototype.directionalNavOffset = function(newOffset) {
	if (typeof newOffset === 'undefined') {
		return this._options.directionalNav.offset;
	} else {
		this._options.directionalNav.offset = newOffset;

		if (this._options.directionalNav.offset != undefined) {
			this._directionalNav.btnNextSlide.style.right = this._options.directionalNav.offset + 'px';
		}

		if (this._options.directionalNav.offset != undefined) {
			this._directionalNav.btnPreviousSlide.style.left = this._options.directionalNav.offset + 'px';
		}

		if (this._options.pagination.enabled
			&& this._options.directionalNav.alignment === this._options.pagination.alignment) {
			TweenLite.set(this._pagination[this._options.pagination.type].container, {
				paddingRight: (this._options.directionalNav.offset + this._directionalNav.btnNextSlide.offsetHeight * 3 / 2) + 'px',
				paddingLeft: (this._options.directionalNav.offset + this._directionalNav.btnPreviousSlide.offsetHeight * 3 / 2) + 'px'
			});
		}
	}
};

Slider.prototype.paginationType = function(newPaginationType) {
	if (this._options.pagination.enabled) {
		if (typeof newPaginationType === 'undefined') {
			return this._options.pagination.type;
		} else {
			if (newPaginationType !== this._options.pagination.type) {
				if (newPaginationType === 'pages' && this._options.mode === 'infinite') {
					throw "Invalid pagination type (\"pages\") for \"infinite\" mode.";
				}

				this._pagination[this._options.pagination.type].container.remove();
				this._options.pagination.type = newPaginationType;

				if (this._options.pagination.type === 'pages') {
					this._updatePagesDOM();
					this.selectSlide(this._pagination['pages'].idxPages[this._currentIdx + this._options.visibleElements - 1].slideIdxStart);
				}

				this._initPagination();

				if (this._options.directionalNav.enabled) {
					this.directionalNavOffset(this._options.directionalNav.offset);
				}
			}
		}
	} else {
		throw "Pagination disabled.";
	}
};

Slider.prototype.animationDuration = function(newDuration) {
	if (typeof newDuration === 'undefined') {
		return this._options.animation.duration;
	} else {
		this._options.animation.duration = newDuration;
	}
};

Slider.attachToElement = function(parent, opts) {
	if (typeof parent === 'undefined' || parent === null) {
		throw "Parent must not be null or \"undefined\"";
	}

	return new Slider(parent, opts);
};

Slider.attachToSelector = function(selector, opts) {
	return Array.prototype.slice.call(document.querySelectorAll(selector)).map(function(element) {
		return Slider.attachToElement(element, opts);
	});
};
