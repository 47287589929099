/**
 * @constructor
 */
function HeightEqualizer(parent, opts) {
  this._parent = parent;
  this._rows = new Array();
  this._options = {
    // which children to select (default: all direct children)
    selectors: opts.selectors || ['> *'],
    // minimal height (default: 0)
    minHeight: opts.minHeight || 0,
    // whether to equalize rows on window resize (default: false)
    equalizeOnResize: opts.equalizeOnResize || false,
    // whether to equalize immediately after initialization (default: false)
    immediateEqualization: opts.immediateEqualization || false,
    // whether to recreate list of children before each equalization (useful for external dom-manipulation)
    // (default: false)
    refetchElements: opts.refetchElements || false,
    // whether to recreate list of rows before each equalization (useful when using grid-systems)
    // (default: false)
    recreateRows: opts.recreateRows || false,
    // whether to animate height change (default: false)
    animate: opts.animate || false,
    // whether to animate the initial equalization (most likely on page load) (default: false)
    animateInitially: opts.animateInitially || false,
    // whether to play equalization animations in sequence (default: false = simultaneously)
    animateSequentially: opts.animateSequentially || false,
    // duration of animation in seconds (only works if 'animate' is set to 'true') (default: 0.5)
    animationDuration: opts.animationDuration || 0.5,
    // array of objects containing event targets (obj.target) and event types (obj.type)
    // which trigger equalization
    equalizeOn: opts.equalizeOn || []
  };

  this._init();
};

/**
 * @private
 */
HeightEqualizer.prototype._init = function() {
  this._elements = this._fetchElements();

  if (this._elements.length > 0 && this._elements[0].elements.length > 0) {
    if (this._options.animate) {
      this._elements.forEach(function(elementsBySelector) {
        elementsBySelector.elements.forEach(function(element) {
          element.setAttribute('data-previous-height', element.offsetHeight);
        });
      });
    }

    this._rows = this._createRows();

    if (this._options.immediateEqualization) {
      this._equalize(this._options.animateInitially);
    }
  }

  if (this._options.equalizeOnResize) {
    this._id = Math.random();

    window.addEventListener('resize', function() {
      waitForFinalEvent(this.equalize.bind(this), 500, "rwd-heightequalizer-" + this._id);
    }.bind(this));
  }

  this._options.equalizeOn.forEach(function(targetAndType) {
    targetAndType.target.addEventListener(targetAndType.type, this.equalize.bind(this));
  }.bind(this));
};

/**
 * @private
 */
HeightEqualizer.prototype._fetchElements = function() {
  return this._options.selectors.map(function(selector) {
    var elements = this._parent == null ? [] : Array.prototype.slice.call(this._parent.querySelectorAll(selector));
    return {
      selector: selector,
      elements: elements
    };
  }.bind(this));
};

/**
 * @private
 */
HeightEqualizer.prototype._createRows = function() {
  var processRow = function(row) {
    // row finished
    maxHeight = Math.max.apply(Math, row.elements.map(function(element) {
      return element.offsetHeight;
    }));
    maxHeight = Math.max(maxHeight, this._options.minHeight);
    row.maxHeight = maxHeight;

    TweenLite.set(row.elements, {height: maxHeight});
  }.bind(this);

  var rows = new Array();
  var key;
  var lastKey;
  var maxHeight;

  this._elements.forEach(function(elementsBySelector) {
    elementsBySelector.elements.forEach(function(element) {
      // hide elements (otherwise the occurring height changes would result in flickering)
      element.setAttribute('data-previous-height', element.offsetHeight);
      TweenLite.set(element, {autoAlpha: 0, height: 'auto'});

      key = elementsBySelector.selector + ' ' + element.getBoundingClientRect().top;

      if (key !== lastKey) {
        if (typeof lastKey !== 'undefined') {
          processRow(rows[lastKey]);
        }
      }

      // refetch top (might have changed because of height change of previous row
      key = elementsBySelector.selector + ' ' + element.getBoundingClientRect().top;

      if (key !== lastKey) {
        lastKey = key;
      }

      if (!(key in rows)) {
        rows[key] = {
          maxHeight: 0,
          elements: new Array()
        }
      }

      rows[key].elements.push(element);
    });
  });

  // last row (missed by loop which accesses the previous row
  processRow(rows[lastKey]);

  // set height back to previous value and show elements
  this._elements.forEach(function(elementsBySelector) {
    elementsBySelector.elements.forEach(function(element) {
      TweenLite.set(element, {autoAlpha: 1, height: element.getAttribute('data-previous-height')});
    })
  });

  var flattenedRows = new Array();

  for (var key in rows) {
    flattenedRows.push(rows[key]);
  }

  return flattenedRows;
};

HeightEqualizer.prototype._equalize = function(animate) {
  if (this._options.refetchElements) {
    this._elements = this._fetchElements();
  }

  if (this._options.recreateRows) {
    this._rows = this._createRows();
  }

  var tl = new TimelineLite({paused: true});
  var numElements = this._rows.length;

  this._rows.forEach(function(row) {
    if (animate) {
      if (this._options.animateSequentially) {
        tl.staggerTo(row.elements, this._options.animationDuration / numElements, {height: row.maxHeight}, this._options.animationDuration / numElements);
      } else {
        tl.to(row.elements, this._options.animationDuration, {height: row.maxHeight}, 0);
      }
    } else {
      tl.set(row.elements, {height: row.maxHeight});
    }
  }.bind(this));

  tl.play();
};

HeightEqualizer.prototype.equalize = function() {
  return this._equalize(this._options.animate);
};

HeightEqualizer.attachToElement = function(parent, opts) {
  return new HeightEqualizer(parent, opts);
};

HeightEqualizer.attachToSelector = function(selector, opts) {
  return Array.prototype.slice.call(document.querySelectorAll(selector)).map(function(element) {
    return HeightEqualizer.attachToElement(element, opts);
  });
};

HeightEqualizer.equalize = function(elements, animate, animationDuration) {
  var mockEqu = {
    _elements: [{
      selector: '',
      elements: elements
    }],
    _options: {
      animate: animate,
      animationDuration: animationDuration,
      minHeight: 0
    }
  };

  mockEqu._rows = HeightEqualizer.prototype._createRows.call(mockEqu);

  elements.forEach(function(element) {
    element.setAttribute('data-previous-height', element.offsetHeight);
  });

  HeightEqualizer.prototype._equalize.call(mockEqu, animate);
};