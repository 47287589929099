var Expandable = (function(window, document, undefined) {
  Expandable.DIRECTION_DOWN = 1;
  Expandable.DIRECTION_UP = 2;
  Expandable.DIRECTION_RIGHT = 4;
  Expandable.DIRECTION_LEFT = 8;

  Expandable.attach = function(parent, opts) {
    return new Expandable(parent, opts);
  };

  var DEFAULT_OPTIONS = {
    direction: Expandable.DIRECTION_DOWN,
    contentSelector: null,
    triggerSelectors: null,
    push: false,
    animation: {
      enabled: false,
      duration: 0.5,
      force3D: false,
      ease: Linear.easeNone
    },
    onExpandStart: function() {},
    onExpandComplete: function() {},
    onCollapseStart: function() {},
    onCollapseComplete: function() {},
    onProgressUpdate: function() {}
  };

  var waitForFinalEvent = window.waitForFinalEvent || (function() {
      var timers = {};

      return function(callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }

        if (timers[uniqueId]) {
          clearTimeout(timers[uniqueId]);
        }

        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();

  function extendOptions(obj, defaultObj) {
    for (var opt in defaultObj) {
      if (obj[opt] === undefined) {
        obj[opt] = defaultObj[opt];
      } else if (typeof defaultObj[opt] === 'object') {
        extendOptions(obj[opt], defaultObj[opt]);
      }
    }
  }

  function Expandable(parent, opts) {
    this._id = Math.random();
    this._init(parent, opts);
  }

  Expandable.prototype._init = function(parent, opts) {
    this._parent = parent;
    this._options = opts || {};
    this._expanded = false;
    this._enabled = true;

    extendOptions(this._options, DEFAULT_OPTIONS);

    this._parentsOffset = function() {
      var parentComputedStyle = window.getComputedStyle(this._parent);

      if (this._options.direction === Expandable.DIRECTION_DOWN) {
        return parseFloat(parentComputedStyle.getPropertyValue('margin-bottom'));
      } else if (this._options.direction === Expandable.DIRECTION_UP) {
        return parseFloat(parentComputedStyle.getPropertyValue('margin-top'));
      } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
        return parseFloat(parentComputedStyle.getPropertyValue('margin-right'));
      } else {
        return parseFloat(parentComputedStyle.getPropertyValue('margin-left'));
      }
    }.bind(this)();

    if (this._options.contentSelector == null) {
      throw "Content selector must not be null.";
    }

    this._content = parent.querySelector(this._options.contentSelector);
    this._content.classList.add('collapsed');

    if (this._options.animation.enabled) {
      this._content.classList.add('animated');
    }

    if (this._options.triggerSelectors == null) {
      throw "Trigger selector must not be null.";
    }

    this._triggers = Array.prototype.slice.call(this._parent.querySelectorAll(this._options.triggerSelectors));
    this._triggerHandler = function(evt) {
      return this._onTriggerClick.bind(this, trigger);
    }.bind(this);
    this._hookUpTriggers();

    window.addEventListener('resize', function() {
      waitForFinalEvent(function() {
        this._updateContentSize();

        if (this._expanded && this._options.push) {
          this._updateParentMargin();
        }
      }.bind(this), 500, "rwd-expandable-" + this._id);
    }.bind(this));

    this._updateContentSize();
  };

  Expandable.prototype._onTriggerClick = function(trigger, evt) {
    evt.preventDefault();

    if (this._expanded) {
      this.collapse(trigger);
    } else {
      this.expand(trigger);
    }
  };

  Expandable.prototype._hookUpTriggers = function() {
    if (this._listeners === undefined) {
      this._listeners = {};

      for (var i = 0, len = this._triggers.length; i < len; i++) {
        this._listeners[i] = this._onTriggerClick.bind(this, this._triggers[i]);
        this._triggers[i].addEventListener('click', this._listeners[i]);
      }
    }

    for (var i = 0, len = this._triggers.length; i < len; i++) {
      this._triggers[i].addEventListener('click', this._listeners[i]);
    }
  };

  Expandable.prototype._unhookTriggers = function() {
    for (var i = 0, len = this._triggers.length; i < len; i++) {
      this._triggers[i].removeEventListener('click', this._listeners[i]);
    }
  };

  Expandable.prototype._resetParentMargin = function() {
    if (this._options.direction === Expandable.DIRECTION_DOWN) {
      this._parent.style.marginBottom = 0;
    } else if (this._options.direction === Expandable.DIRECTION_UP) {
      this._parent.style.marginTop = 0;
    } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
      this._parent.style.marginRight = 0;
    } else {
      this._parent.style.marginLeft = 0;
    }
  };

  Expandable.prototype._updateParentMargin = function() {
    if (this._options.direction === Expandable.DIRECTION_DOWN) {
      this._parent.style.marginBottom = this._contentSize + 'px';
    } else if (this._options.direction === Expandable.DIRECTION_UP) {
      this._parent.style.marginTop = this._contentSize + 'px';
    } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
      this._parent.style.marginRight = this._contentSize + 'px';
    } else {
      this._parent.style.marginLeft = this._contentSize + 'px';
    }
  };

  Expandable.prototype._updateContentSize = function(evt) {
    if (!this._expanded) {
      this._content.classList.remove('collapsed');
    }

    this._contentSize = function() {
      if (this._options.direction === Expandable.DIRECTION_DOWN || this._options.direction === Expandable.DIRECTION_UP) {
        return this._content.offsetHeight + this._parentsOffset;
      } else {
        return this._content.offsetWidth + this._parentsOffset;
      }
    }.bind(this)();

    if (!this._expanded) {
      this._content.classList.add('collapsed');
    }
  };

  Expandable.prototype.expand = function(trigger) {
    if (!this._expanded) {
      var otherTriggers = this._triggers.filter(function(tr) { return tr !== trigger; });

      if (this._options.animation.enabled) {
        if (this._animation === undefined) {
          var contentAnimationTo = function() {
            if (this._options.direction === Expandable.DIRECTION_DOWN || this._options.direction === Expandable.DIRECTION_UP) {
              return {
                height: this._contentSize + 'px'
              };
            } else {
              return {
                width: this._contentSize + 'px'
              };
            }
          }.bind(this)();
          contentAnimationTo.force3D = this._options.animation.force3D;
          contentAnimationTo.ease = this._options.animation.ease;

          var contentAnimationFrom = function() {
            if (this._options.direction === Expandable.DIRECTION_DOWN || this._options.direction === Expandable.DIRECTION_UP) {
              return {
                height: 0
              };
            } else {
              return {
                width: 0
              };
            }
          }.bind(this)();

          var parentAnimationTo = function() {
            if (this._options.direction === Expandable.DIRECTION_DOWN) {
              return {
                marginBottom: this._contentSize + 'px'
              }
            } else if (this._options.direction === Expandable.DIRECTION_UP) {
              return {
                marginTop: this._contentSize + 'px'
              }
            } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
              return {
                marginRight: this._contentSize + 'px'
              }
            } else {
              return {
                marginLeft: this._contentSize + 'px'
              }
            }
          }.bind(this)();
          parentAnimationTo.force3D = this._options.animation.force3D;
          parentAnimationTo.ease = this._options.animation.ease;

          var parentAnimationFrom = function() {
            if (this._options.direction === Expandable.DIRECTION_DOWN) {
              return {
                marginBottom: 0
              }
            } else if (this._options.direction === Expandable.DIRECTION_UP) {
              return {
                marginTop: 0
              }
            } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
              return {
                marginRight: 0
              }
            } else {
              return {
                marginLeft: 0
              }
            }
          }.bind(this)();

          var parentProperty = function() {
            if (this._options.direction === Expandable.DIRECTION_DOWN) {
              return 'margin-bottom';
            } else if (this._options.direction === Expandable.DIRECTION_UP) {
              return 'margin-top';
            } else if (this._options.direction === Expandable.DIRECTION_RIGHT) {
              return 'margin-right';
            } else {
              return 'margin-left';
            }
          }.bind(this)();

          var contentProperty =
            (this._options.direction === Expandable.DIRECTION_DOWN || this._options.direction === Expandable.DIRECTION_UP)
              ? 'height' : 'width';

          this._animation = new TimelineLite({
            paused: true,
            onStartScope: this,
            onStartParams: [this._parent, trigger, otherTriggers],
            onStart: function(parent, trigger, otherTriggers) {
              this._options.onExpandStart(parent, trigger, otherTriggers);
              this._content.classList.remove('collapsed');
              this._parent.classList.add('open');
            },
            onCompleteScope: this,
            onCompleteParams: [this._parent, trigger, otherTriggers],
            onComplete: this._options.onExpandComplete,
            onReverseCompleteScope: this,
            onReverseComplete: function() {
              this._content.classList.add('collapsed');
              this._parent.classList.remove('open');
              this._parent.style.removeProperty(parentProperty);
            },
            onComplete: function(parent, triger, otherTriggers) {
              if (!this._options.push) {
                this._content.style.removeProperty(contentProperty);
              }

              this._options.onExpandComplete(parent, trigger, otherTriggers);
            },
            onUpdateParams: [this._parent, trigger, otherTriggers],
            onUpdate: function(parent, trigger, otherTriggers) {
              this._options.onProgressUpdate(parent, trigger, otherTriggers, this._animation.progress());
            }.bind(this)
          });

          this._animation.set(this._content, contentAnimationFrom, 0);
          this._animation.to(this._content, this._options.animation.duration, contentAnimationTo);

          if (this._options.push) {
            this._animation.set(this._parent, parentAnimationFrom, ('-=' + this._options.animation.duration) );
            this._animation.to(this._parent, this._options.animation.duration, parentAnimationTo, ('-=' + this._options.animation.duration));
          }
        }

        this._animation.play();
      } else {
        this._options.onExpandStart(this._parent, trigger, otherTriggers);
        this._parent.classList.add('open');
        this._content.classList.remove('collapsed');

        if (this._options.push) {
          this._updateParentMargin();
        }

        this._options.onProgressUpdate(this._parent, trigger, otherTriggers, 1);
        this._options.onExpandComplete(this._parent, trigger, otherTriggers);
      }

      this._expanded = true;
    }
  };

  Expandable.prototype.collapse = function(trigger) {
    if (this._expanded) {
      var otherTriggers = this._triggers.filter(function(tr) { return tr !== trigger; });

      if (this._options.animation.enabled) {
        this._animation.reverse();
      } else {
        this._options.onCollapseStart(this._parent, trigger, otherTriggers);
        this._parent.classList.remove('open');
        this._content.classList.add('collapsed');

        if (this._options.push) {
          this._resetParentMargin();
        }

        this._options.onProgressUpdate(this._parent, trigger, otherTriggers, 0);
        this._options.onCollapseComplete(this._parent, trigger, otherTriggers);
      }

      this._expanded = false;
    }
  };

  Expandable.prototype.isExpanded = function() {
    return this._expanded;
  };

  Expandable.prototype.enable = function() {
    if (!this._enabled) {
      if (!this._expanded) {
        this._content.classList.add('collapsed');
      }

      this._hookUpTriggers();
      this._enabled = true;
    }
  };

  Expandable.prototype.disable = function() {
    if (this._enabled) {
      if (!this._expanded) {
        this._content.classList.remove('collapsed');
      }

      this._unhookTriggers();
      this._enabled = false;
    }
  };

  return Expandable;
})(window, document);